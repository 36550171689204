import * as React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.scss"
import { Helmet } from "react-helmet"

export const ShowsPageTemplate = ({ shows }) => {
  const showsUI = shows.map(show => {
    return (
      <div className="tileTwo">
        <h2 style={{ color: "white" }}>{show.date}</h2>
        <p className="yellow">{show.venue}</p>
      </div>
    )
  })
  return (
    <div className="container sectionThree">
      <div className="content">
        {showsUI}
        <a
          target="_blank"
          href="https://dedicated-founder-2746.ck.page/02a94ba0d8"
        >
          <div class="pointer">
            <p>Subscribe To Newsletter</p>
          </div>
        </a>
        <a target="_blank" href="https://michelpasvar.bigcartel.com/">
          <div class="pointer">
            <p>Shop Merch</p>
          </div>
        </a>
      </div>
    </div>
  )
}

const ShowsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ShowsPageTemplate shows={frontmatter.shows} />
    </Layout>
  )
}

ShowsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ShowsPage

export const pageQuery = graphql`
  query ShowsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "shows-page" } }) {
      frontmatter {
        shows {
          date(formatString: "MMMM Do, YYYY")
          venue
        }
      }
    }
  }
`
